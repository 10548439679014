:root {
          --mastercolor: #1D3B6D;
          --mastercolor25p:rgb(1 40 89 / 25%);
          --masterdark:#003C75;
          --masterdark2:#00576F;
          --masterdark3:#0E5BA2;
          --mastercolor2:#96DE5D;
          --mastercolor2_d1:#82A81B;
          --mastercolor2_d2:#85AB45;
          --mastercolor25p2:rgb(26 130 226 / 25%);
          --gray:#D1E2EC;
          --gray1:#112A42;
          --gray2:#F1F5F7;
          --gray3:#D7D7D7;
          --gray4:#363636;
          --gray5:#f1f1f1;
          --gray6:#E1E1E1;
          --gray7:#8C9CA9;
          --gray8:#505A63;
          --gray9:#212529;
          --gray10:#687182;
          --gray11:#F3F5F7;
          --gray12:#D9D9D9;
          --darkGray:#495057;
          --dark1:#1E1E1E;
          --light:#f8f9fb;
          --light1:#EDF1F4;
          --light2:#F2F9FF;
          --light3:#FCFCFC;
          --light4:#F9FAFB;
          --light5:rgba(29, 59, 109, 0.1);
          --light6:rgba(29, 59, 109, 0.30);
          --light7:rgba(29, 59, 109, 0.05);


          --dark75p:rgb(57 57 57 / 75%);
          --light75p:rgb(183 183 183 / 75%);
          --white75p:rgb(255 255 255 / 75%);
          --white35p:rgb(255 255 255 / 35%);
          --green1: #39970C;
          --green2: #76FC93;
          --green_light:rgba(193, 221, 117, 0.30);
          --black:#000;
          --white:#fff;
          --logo-size:95px;
          /* --font-family1:"Roboto"; */
          --font-family1:'Mulish', sans-serif !important;

          --chart-success:#2EE1A1;
          --chart-danger:#FF3B3B;
          --chart-warning:#FFC75B;
          --chart-primary:#00B3E3;
          --chart-decline:#B7C700;
          --chart-pink:#D885A3;
          --chart-orange:#F76E11;
          --fw_normal:400;
          --fw_medium:500;
          --fw_bold:600;


      }
      

      @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto/Roboto-Thin.ttf');
        font-weight: 100;
        }
        @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto/Roboto-Light.ttf');
        font-weight: 300;
        }
        @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto/Roboto-Regular.ttf');
        font-weight: 400;
        }
        @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto/Roboto-Medium.ttf');
        font-weight: 500;
        }
        @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto/Roboto-Bold.ttf');
        font-weight: 700;
        }
        @font-face {
        font-family: 'Roboto';
        src: url('../fonts/Roboto/Roboto-Black.ttf');
        font-weight: 900;
        }
        

        
       /* //Mulish */
       @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-ExtraLight.ttf');
        font-weight: 200;
      }
      @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-Light.ttf');
        font-weight: 300;
      }
      @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-Regular.ttf');
        font-weight: 400;
      }
      @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-Regular.ttf');
        font-weight: 400;
      }
      @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-Medium.ttf');
        font-weight: 500;
      }
       @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-SemiBold.ttf');
        font-weight: 600;
      }
      @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-Bold.ttf');
        font-weight: 700;
      }
      @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-ExtraBold.ttf');
        font-weight: 800;
      }
      @font-face {
        font-family: 'Mulish';
        font-style: normal;
        src: url('../fonts/mulish/Mulish-Black.ttf');
        font-weight: 900;
      }
 
      
        