html,body{
    height: 100%;
}

body{

  font-family: var(--font-family1) !important;
  line-height: 1.42857143;
  font-size: 14px;
}
label{
  color: var(--gray2);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}
.input_bx1:focus{
  outline: unset;
}
ul.master_color li::marker {
  color: var(--mastercolor);
}
.app_logo{
  max-width: var(--logo-size);
}
.avatar-xs{
  height: 2rem;
    width: 2rem;
}
.uderline{
text-decoration: none;
}
.with_uderline{
  text-decoration: underline;
  }


/* START CUSTOM TAB TYPE 01 */
.nav_tab_t1.nav-tabs{
  border-bottom: transparent;
}
.nav_tab_t1.nav-tabs .nav-item{
  border:unset;
  flex-grow: 1;
}
.nav_tab_t1.nav-tabs .nav-link{
  color: var(--gray2);
  font-weight: 700;    
  padding: 15px 20px 12px 20px;
  text-align: center;
  font-size: 14px;
}
.nav_tab_t1.nav-tabs .nav-link:focus, .nav_tab_t1.nav-tabs .nav-link:hover{
  border-color:transparent;
  color: var(--mastercolor);
}
.nav_tab_t1.nav-tabs .nav-item.show .nav-link, .nav_tab_t1.nav-tabs .nav-link.active {
  border-color: transparent;
  color: var(--mastercolor);
  border-bottom: 3px solid var(--mastercolor);
}
.nav_tab_t1.nav-tabs .nav-link.active:focus, .nav_tab_t1.nav-tabs .nav-link.active:hover{
  border-color: transparent;
  color: var(--mastercolor);
  border-bottom: 3px solid var(--mastercolor);
}
/* END CUSTOM TAB TYPE 01 */
.nav_tab_t1.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  color: var(--white);
  background-color: var(--mastercolor);
}
.nav-link:focus, .nav-link:hover{
  color: var(--mastercolor);
}
.nav_tab_t1.nav-pills .nav-link {
  color: #ADADAD;
  font-weight: 600;
  border-radius: 2rem;
  padding: 0.4rem 1rem;
}

.nav_tab_t2.nav-tabs .nav-link {
  color: #ADADAD;
  font-weight: 600;
  padding: 15px 20px 12px 20px;
  text-align: center;
  font-size: 16px;
}
.nav_tab_t2.nav-tabs .nav-link .tab_counts{
  background-color: var(--white);
  color: #ADADAD;
  border: 1px solid #ADADAD;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1;
    position: relative;
}
.nav_tab_t2.nav-tabs .nav-link .tab_counts.completed{
  background-color: var(--green1);
  color: var(--white);
  border: 1px solid var(--green1);
}
.nav_tab_t2.nav-tabs .nav-link:focus, .nav_tab_t2.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.nav_tab_t2.nav-tabs{
  border: unset;
}
.nav_tab_t2.nav-tabs .nav-item.show .nav-link, .nav_tab_t2.nav-tabs .nav-link.active{
  color: var(--mastercolor);
    background-color: transparent;
    border-color: transparent;
}

.nav_bar_outer{
  max-width: 1000px;
  position: relative;
  margin: auto;
}
.nav_tab_t2_line{
  position: absolute;
    top: 28px;
    left: 12%;
    background-color: var(--masterlight);
    height: 1px;
    width: 80%;
}

.nav_tab_t3.nav-tabs .nav-link {
  color: #000000;
    background: #dbdbdb;
    font-weight: 500;
    padding: 8px 20px 8px 20px;
    text-align: center;
    font-size: 12px;
    border-radius: 45px;
}
.nav_tab_t3.nav-tabs .nav-link .tab_counts{
  background-color: var(--white);
  color: #ADADAD;
  border: 1px solid #ADADAD;
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1;
    position: relative;
}
.nav_tab_t3.nav-tabs .nav-link .tab_counts.completed{
  background-color: var(--green1);
  color: var(--white);
  border: 1px solid var(--green1);
}
.nav_tab_t3.nav-tabs .nav-link:focus, .nav_tab_t3.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.nav_tab_t3.nav-tabs{
  border: unset;
}
.nav_tab_t3.nav-tabs .nav-item.show .nav-link, .nav_tab_t3.nav-tabs .nav-link.active{
  color: var(--white);
    background-color:  var(--mastercolor2);
    border-color: transparent;
}
.nav_tab_t3.nav-tabs .nav-item.show .nav-link .tab_counts, .nav_tab_t3.nav-tabs .nav-link.active .tab_counts{
  background-color: var(--mastercolor);
  color: #fff;
  border: 1px solid var(--mastercolor);
}
.tb_hide_search .dataTables_filter{
  display: none;
}
.tb_hide_info .dataTables_info{
  display: none;
}
.table_type01 table{
  padding: 0!important;
  padding-bottom: 5px!important;
  border: unset!important;
  border-radius: 5px!important;
}
.table_type01 table.dataTable th, .table_type01 table.dataTable td{
  vertical-align: middle;
}
.table_type01 table.dataTable thead th{
  border: unset;
  border-bottom: 2px solid var(--gray1);
  background-color: #f8f9fa;
  text-transform:none;
  font-size: 14px;
  padding:0.75rem 1rem 0.75rem 0.75rem;
}
.table_type01 table.dataTable thead th:first-child{
  border-top-left-radius: 5px !important;
}
.table_type01 table.dataTable thead th:last-child{
  border-top-right-radius: 5px !important;
}
.table_type01 table.dataTable tbody td{
  border: unset;
  padding: 0.75rem;
  font-size: 12px;
  border-bottom: 1px solid  var(--gray1);
}
.table_type01 table.dataTable tbody tr:last-child td{
  border-bottom: unset;
}
.table_type01 .dataTables_paginate{
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}
.table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button.current, .table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  color: white !important;
  background: var(--mastercolor);
  border: 1px solid  var(--mastercolor);
  border-radius: 4px;
}
.table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button:hover{
  background: var(--mastercolor);
  border: 1px solid  var(--mastercolor);
  opacity: 0.7;
}
.table_type01 table.dataTable tbody td a{
 color: var(--mastercolor);
}

.table_type01 .dataTables_wrapper .dataTables_paginate .paginate_button{
  line-height: 100%;
  padding: 5px 8px;
}
.table_type02.table>:not(:first-child){
  border-top: 1px solid #dde2e6;
}
table.valign_middle th, table.valign_middle td {
  vertical-align: middle;
}
.form_bx_stack label{
 margin-bottom: 5px;
}
.form_bx_stack .data{

}
.form_bx_inline{
  display: flex;
}
.form_bx_inline label{
  margin-bottom: 0;
  padding-top: 6px;
}
.form_bx_inline .data{
  margin-left: 5px;
}
.input_withicon{position: relative;}
.input_withicon.start .with_text{
  padding-left: 35px !important;
}
.input_withicon.start .with_icon{
  padding-left: 35px !important;
}
.input_withicon.end .with_icon{
  padding-right: 40px !important;
}
.input_withicon .icn{
  position: absolute;
    top: 0;
    height: 36px;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
}
.input_withicon.start .icn{
    left: 2px;
}
.input_withicon.end .icn{
    right: 10px;
}


.select_dropdown .with_icon_end{
  padding-right: 30px;
}
.select_dropdown .icn_end{
  position: absolute;
  right: 2px;
  top: 2px;
}
.select2-container{
  z-index: 1055;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--mastercolor)!important;
  color: white;
}
.select2-container--open .select2-dropdown--below{
  border: unset;
  padding: 5px 0px;
  margin-top: 1px;
  box-shadow: 1px 1px 10px 2px rgb(204 204 204 / 36%);
}
.select2-results__option {
  border-top: 1px solid var(--gray1);
}
.select2-search--dropdown .select2-search__field:focus-within{
  outline: unset;
}
.select2-container--default .select2-search--dropdown .select2-search__field{
  border: 1px solid var(--gray)!important;
  border-radius: 3px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid var(--gray)!important;
}
.select2-container--default .select2-selection--multiple{
  border: 1px solid var(--gray)!important;
}
.select2-container--default .select2-search--inline .select2-search__field {
  padding: 0 10px;
}
.select_bx1{
  position: relative;
}
.select_bx1 .select2-container{
  z-index: 1;
  width: 100% !important;
}
.select_bx1 .select2-container .select2-selection--single .select2-selection__rendered{
  line-height: 35px;
}
.select_bx1.with_left_icon .select2-container .select2-selection--single .select2-selection__rendered{
  padding-left: 30px;
}
.select_bx1 .select2-container .select2-selection--single .select2-selection__rendered{
  padding-right: 30px;
}
.select_bx1.select_master_line .select2-container .select2-selection--single .select2-selection__rendered{
  color: var(--mastercolor);
}
.select_bx1 .select2-container--default .select2-selection--single{
  border:unset;
  background-color: transparent;
  height: 36px;
}
.select_bx1.select_with_border .select2-container--default .select2-selection--single{
  border:1px solid var(--gray)!important;
}
.select_bx1.select_with_border.select_master_line .select2-container--default .select2-selection--single{
  border:1px solid var(--mastercolor)!important;
}

.select_bx1 .select2-container--default .select2-search--inline .select2-search__field{
  padding: 1px 12px;
}
.select_bx1 .select2-container--default .select2-selection--multiple .select2-selection__rendered{
  padding: 1px 12px;
}
.select_bx1 .select2-container .select2-selection--multiple{
  min-height: 36px;
}
.select_bx1 .left_icn{
  position: absolute;
  left: 2px;
  top: 2px;
}
.select_bx1 .right_icn{
  position: absolute;
  right: 2px;
  top: 3px;
  color: var(--gray2);
}
.select_bx1.select_master_line .right_icn{
  color: var(--mastercolor);
}
.select_bx1 .select2-container.select2-container--open + .right_icn{
  transform: rotate(180deg);
  color: var(--mastercolor);
}
.select_bx1 .select2-container--default .select2-selection--single .select2-selection__arrow{
  display: none;
}



.custom_scroll1::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.custom_scroll1::-webkit-scrollbar-track {
  border-radius: 10px;
  background:#F0F0F0;
}
.custom_scroll1::-webkit-scrollbar-thumb {
  background: #D9D9D9; 
  border-radius: 10px;
}
.custom_scroll1::-webkit-scrollbar-thumb:hover {
  background: #c8c8c8; 
}


.form-check-input[type=checkbox] {
  border-radius: 0.15em;
}
input[type="date"]{
    position: relative;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}



/* Month Picker */
.ui-widget-header .ui-icon{
  left: 5px;
    top: 5px;
    position: relative;
}
.ui-state-disabled{
  pointer-events: none;
}


.px_loader{
  z-index: 1;
}
.px_loader_content {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 72px;
}
.px_loader_content div {
  position: absolute;
  border: 4px solid var(--mastercolor);
  opacity: 1;
  border-radius: 50%;
  animation: loader_anim 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.px_loader_content div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loader_anim {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* Loader */

.circle-loader-shape {
  animation: a 1.5s linear infinite;
    height: 70px;
    width: 70px;
}
.circle-path {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation: b 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes a {
  to {
      transform: rotate(1turn);
  }
}
@keyframes b {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }
  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
  }
  to {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
  }
}

.text_master .form-check-label{
  color: var(--mastercolor)!important;
}


.disable_div{
  opacity: 0.5;
  pointer-events: none;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: var(--mastercolor);
}

.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
  color: var(--gray2);
}
.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.15rem var(--mastercolor25p);
}






.form-control{
  border: 1px solid var(--gray);
  font-size: 14px;
    padding: 8px 15px;
}
.form-control:hover, .form-control:focus{
box-shadow: unset;
border: 1px solid var(--gray);
}
.form-control::placeholder{
  color: #A1BAD2;
}
/* MODAL  */
.modal-content {
  border: 1px solid #f6f6f6;
}
.range_slider {
  -webkit-appearance: none;
  appearance: none;
  opacity: 0.85;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.range_slider:hover {
  opacity: 1;
}

.range_slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--mastercolor);
  cursor: pointer;
}
.range_slider::-webkit-slider-thumb:hover {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: var(--mastercolor);
  cursor: pointer;
}
.range_slider::-webkit-slider-thumb:active {
  background: var(--mastercolor);
  border: unset;
  box-shadow: 0px 0px 0px 4px rgb(1 40 89 / 16%);
  cursor: pointer;
}
.range_slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: var(--mastercolor);
  cursor: pointer;
}
.NotifMenu{
  width: 300px;
}
.NotifMenuItem{

  max-height: 300px;
}
.NotifMenu.dropdown-menu
{
    z-index: unset;
    background-color: unset;
    background-clip: unset;
    border: none;
    border-radius: unset;
}
.arco-spin{
  height: 100%;
  width: 100%;
}
.arco-spin .arco-spin-children{
  height: 100%;
}


/* ---------- START ARCO COMPONENTS ----------- */
.arco_select .arco-select{
  width: 100%  !important;
  background-color: var(--white) !important;
}
.arco_custom_date .arco-picker-disabled{
  background-color: #e9ecef !important;
  opacity: 1;

}

.arco_select_1 .arco-select .arco-select-view{
 border: none !important  ;
}

.arco_select_1 .arco-select-size-default.arco-select-single .arco-select-view{

  width: 30px;
  height: 30px !important;
  padding: 0 !important;
}

.arco_select_1 div{
  margin-bottom: 0 !important;
}

.arco_select .arco-select-disabled .arco-select-view{
  background-color: #e9ecef !important;
  opacity: 1;
}

.arco_select .arco-select-disabled .arco-select-view input::placeholder{
  color: black !important;
}
.arco_select .arco-select .arco-select-view {
  height: 35.6px !important;
  border: 1px solid var(--gray) !important;
  border-radius: 6px !important;
  background: inherit;
}

.arco_select .arco-select:hover .arco-select-view{
  background:none !important;
}

.arco_select .arco-select-single .arco-select-view-value {
  line-height: 2.9;
  font-size: 12px;
}
.arco_select .arco-select-size-default.arco-select-single input {
  font-size: 12px;
}
.arco_select .arco-select-size-default.arco-select-single input::placeholder {
  color: #000 !important;
  font-weight: 400;
  font-family: var(--font-family1) !important;
}

/* .arco_table1 .arco-table-content-inner thead .arco-table-th{
 background-color: var(--mastercolor);
 color: var(--white);
} */
.arco_table1 .arco-table-content-scroll{
 overflow: unset;
}
.arco-trigger{
  z-index: 1055;
}
.arco-message-wrapper {
  z-index: 1055;
}
.arco_rangepicker .arco-picker.arco-picker-range{
  width: 100%  !important;
  background-color: var(--white)!important;
  height: 35.6px;
  border: 1px solid var(--gray);
  border-radius: 0.25rem!important;
}
.arco_rangepicker .arco-picker-size-default input {
  font-size: 12px;
}
.arco-picker-cell-selected .arco-picker-date-value, .arco-picker-cell-selected:hover .arco-picker-date-value {
  background-color: var(--mastercolor);
}

.arco_slider .arco-slider-bar{
  background-color: var(--mastercolor);
}
.arco_slider .arco-slider-button::after {
  content: '';
  border: 2px solid  var(--mastercolor);

}

input[name='button-radio-group']:focus-visible + .arco-btn {
  box-shadow: 0 0 0 2px var(--color-primary-light-3);
}


.custom-radio-card {
  padding: 10px 16px;
  box-sizing: border-box;
}

.arco-radio-group .arco-radio {
  margin-right: 0;
  width: 100%;
}

.custom-radio-card-mask-dot {
  width: 8px;
  height: 8px;
}

input[name='card-radio-group']:focus-visible + .custom-radio-card {
  box-shadow: 0 0 0 2px var(--color-primary-light-3);
}

.custom-radio-card-checked {
  background-color: var(--color-primary-light-1);
}

.custom-radio-card:hover .custom-radio-card-title,
.custom-radio-card-checked .custom-radio-card-title {
  color: rgb(var(--primary-6));
}

.custom-radio-card-checked .custom-radio-card-mask-dot {
  background-color: rgb(var(--primary-6));
}

.arco_inputsearch .arco-input-search{
  border: 1px solid var(--gray);
    border-radius: 0.25rem!important;
    height: 35.6px;
}
.arco_inputsearch .arco-input-inner-wrapper {
  background-color: var(--white)!important;
}
.arco_inputsearch .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
  border-color: transparent;
  box-shadow: unset;
}
.arco_inputsearch .arco-btn-primary:not(.arco-btn-disabled) {
  background-color: var(--mastercolor);
  transition: all 0.3s;
}

.arco_inputsearch .arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  background-color: var(--mastercolor);
  opacity: 0.8;
}

.arco_inputsearch .arco-input-inner-wrapper .arco-input {
 font-size: 12px;
}
.arco_custom_date .arco-picker-disabled input[disabled]{
  -webkit-text-fill-color: black;
}
.arco_custom_date .arco-picker{
  background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 0.25rem!important;
    height: 35.6px;
    width: 100%!important;
}
.arco_custom_date .arco-picker-size-default input{
  font-size: 12px;
}


.customRadioswitch .arco-radio-group-type-button{
  margin-right: 0 !important;
}

.customRadio .arco-radio-button::after{
  background-color: var(--mastercolor) !important;
}

.customRadio .arco-radio-button-inner {
  color: var(--white);
}

.customRadio .arco-radio-button:hover {
  background-color: var(--color-bg-5);
  color: var(--mastercolor) !important;
}

.customRadio .arco-radio-button:hover .arco-radio-button-inner  {
  color: var(--mastercolor) !important;
}

/* ---------- END ARCO COMPONENTS ----------- */

/* sidebar-- */
.menu-demo {
}
.menu-demo .css-1wvake5{
  border-color: var(--mastercolor) !important;
  width: inherit !important;
  min-width: inherit !important;
}
.menu-demo .css-dip3t8 {
  background: var(--mastercolor) !important;
}

.menu-demo .ps-menu-button .css-12w9als{
  font-size: 16px !important;
  font-weight: 500;
  font-family: var(--font-family1) !important;
  color: var(--white);
}
.menu-demo .css-16jesut >.ps-menu-button:hover {
  background-color: inherit;
}

.menu-demo .css-honxw6 {
  transform: inherit;
  width: inherit;
  height: inherit;
  margin-bottom: 4px;
  border-right: inherit; 
}

.menu-demo .css-honxw6::before {
  content: '';
  position: absolute;
  right: 13px;
  ;
  width: 12px;
  height: 1.5px;
  background: #525252;
}

.menu-demo .css-honxw6::after {
  content: '';
  position: absolute;
  width: 12px;
  height: 1.5px;
  right: 13px;
  transform: rotate(90deg);
  background: #525252;
}

.menu-demo .css-jn69v9 {
  transform: inherit;
  width: inherit;
  height: inherit;
  margin-bottom: 4px;
}

.menu-demo .css-jn69v9::before {
  content: '';
  position: absolute;
  right: 13px;
  ;
  width: 12px;
  height: 1.5px;
  background: #525252;

}

.menu-demo .ps-menu-button {
  padding-left: 15px !important;
  position: relative;
}

.menu-demo .css-ewdv3l .ps-submenu-content{
  margin-top: 0.6rem;
  background: var(--mastercolor);
}

.menu-demo .css-ewdv3l .ps-submenu-content .ps-menu-button{
  /* border-radius: 8px;
  border: 1px solid rgba(29, 59, 109, 0.30);
  background: #FFF !important;
  margin-bottom: 0.5rem; */
}

.menu-demo .css-ewdv3l .ps-submenu-content .ps-menu-button .css-12w9als{
  font-size: 16px !important;
}

/* modal-- */
.custom_modal .modal-header{
  padding: 5px 10px !important;
  background: var(--mastercolor);
}

.custom_modal .modal-header .btn-close{
  margin: inherit;
  background: url("../images/images-removebg-preview.png") center/1em auto no-repeat !important;
  opacity: inherit;
  box-sizing: inherit;
}
.custom_modal .modal-header .h6{
  font-family: var(--font-family1) !important;
  margin: inherit !important;
}

.chartHead canvas{
  height: 313px !important;
  width: 100% !important;
}

.sort_label label{
  display: none !important;
}
.sort_box{
 right: 10px;
 top: 10px;
}

/* table--. */
.table_height{
  height: calc(100vh - 190px);
}
.expandable-table {
  width: 100%;
}

.expandable-table th, .expandable-table td {
  padding: 8px;
  text-align: left;
}

.expandable-table th {
  font-size: 16px;
  font-weight: 600;
}

.expandable-table thead tr  {
  background-color: rgba(255, 255, 255, 0) !important;
  font-size: 16px;
  font-weight: 600;
}

.expandable-table .arco-table-border .arco-table-container{
  border-right: none; 
  border-top: none;
}

.expandable-table .arco-table-border .arco-table-th:first-child {
  border-left: none !important;
}

.expandable-table tr {
  cursor: pointer;
}

.arco-table-border .arco-table-th:first-child, .arco-table-border .arco-table-td:first-child{
  border-left: none !important;
}

.expandable-table tbody tr td{
  font-weight: 400;
  background-color: inherit !important;
  padding-left: 20px;
  font-size: 15px !important;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 14px;
  max-width: 200px;
}

.button1:hover{
  background: var(--mastercolor) !important;
  color: #fff !important;
}

.expandable-table tbody tr:nth-child(odd)
{
  background-color: rgba(29, 59, 109, 0.05) ;
}
.expandable-table tr:nth-child(even) 
{
  background-color: #fff !important;
}

.expandable-table .arco-table-cell-mouseenter{
  background: white !important;
}

.arco-table-expand-content .expand_inner_table tbody .arco-table-tr{
  background-color: inherit !important;
}


.expandable-table .arco-table-expand-content tbody tr:nth-child(even){
  background-color: red !important;
}

.expandable-table .arco-table-expand-content tbody tr:nth-child(odd){
  background-color: transparent !important;
}

.arco-table-expand-content .arco-table-td .expand_inner_table .arco-table .arco-table-th:nth-child(even){
  background-color: transparent !important;
}

.arco-table-expand-content .arco-table-td .expand_inner_table .arco-table .arco-table-th:nth-child(odd){
  background-color: transparent !important;
}






.expandable-table .arco-table-header {
  overflow-y: hidden !important;
}

.expandable-table tbody td .active_span{
  font-weight:  400 !important;
}

.expandable-table .arco-table-body{
  height: calc(100vh - 297px) !important;
  max-height: inherit !important;
} 

.expandable-table .arco-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.expandable-table .arco-table-body::-webkit-scrollbar-track {
  background: var(--gray2);
  border: 1px solid var(--gray2);
  border-radius: 10px;
}
.expandable-table .arco-table-body::-webkit-scrollbar-thumb {
  background: var(--mastercolor); 
  border-radius: 10px;
}
.expandable-table .arco-table-body::-webkit-scrollbar-thumb:hover {
  background: #d4e0ea; 
}

.customise_table .arco-table-cell{
  word-break: inherit !important;
}

.customise_table .arco-table-cell .arco-table-cell-wrap-value{
    word-wrap: break-word;
}

.customise_table2 .arco-table-cell{
  word-break: inherit !important;
}

.customise_table2 .arco-table-cell .arco-table-cell-wrap-value{
    word-wrap: break-word;
}

.expand_inner_table{
overflow: hidden;
}
.expand_inner_table table thead tr th {
  width: 100px !important;
font-size: 16px !important;
font-weight: 600 !important;
color: var(--mastercolor) !important;
}

.expand_inner_table table tbody tr td {
  font-size: 14px !important;
  font-weight: 400 !important;
  /* color: var(--mastercolor) !important; */
  padding-top: 0 !important;
}

.expand_inner_table .arco-table-th-item {
  padding-bottom: 0 !important;
  padding-left: 13px !important;
}


.arco-table-expand-content .arco-table-td .expand_inner_table .arco-table .arco-table-th {
  border-bottom: 0 !important;
  width: 0px !important;
}



.expand_inner_table 

.expand_inner_table tr{
  background-color: inherit !important;
}

.custom_pagination .arco-pagination-item{
  border: none;
  box-shadow: 0px 0px 0px 1.032px rgba(70, 79, 96, 0.24) !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #000;
  min-width: 22px;
  border-radius: 5px;
  height: 22px;
  line-height: 32px;
  margin-right: 5px !important;
}

.custom_pagination .arco-pagination .arco-pagination-jumper-input{
  margin-right: 0;
  padding: 0;
}

.custom_pagination .arco-input:focus, .arco-input.arco-input-focus {
  border-color: inherit !important;
  border: none;
  box-shadow: inherit !important;
}

.custom_pagination .arco-input:hover{
  background: inherit !important;
}

.custom_pagination .arco-input{
  background-color: inherit !important;
  font-size: 13px !important;
}

.custom_pagination .arco-pagination-jumper > span {
  font-size: 13px !important;
}

.custom_pagination .arco-pagination-simple .arco-pagination-jumper .arco-pagination-jumper-input{
  width: 20px !important;
}

.custom_pagination .arco-pagination-item-simple-pager .arco-pagination-jumper-separator{
  padding: 0 5px;
}

.custom_pagination .arco-select .arco-select-view {
  background-color: inherit;
  border: none !important;
}

.custom_pagination .arco-select .arco-select-view:hover{
  background-color: inherit !important;
}

.custom_pagination .custom_pagination .arco-select .arco-select-view {
  background-color: inherit !important;
  border: none !important;
}

.custom_pagination .arco-select.arco-select-focused .arco-select-view{
  background-color: inherit !important;
}

.custom_pagination .arco-pagination-list{
  order: 3;
}

.custom_pagination .pagination-wrapper{
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.custom_pagination .pagination-wrapper .arco-pagination-total-text {
  color: rgba(104, 113, 130, 1) !important;
  font-size: 13px;
}

.custom_pagination .arco-select-size-default.arco-select-single .arco-select-view{
  color: rgba(104, 113, 130, 1) !important;
  font-size: 13px !important;
}

.headStyle1{
  border-radius: 20px 20px 0 0;
}

.headStyle {
  width: 60px;
  height: 46px;
  position: absolute;
  top: -4px;
  right: -57px;
  background-image: radial-gradient(circle at 100% 0, transparent 0%, transparent 60px, #ebeff3 60px);
}
.headStyle2{
  width: 60px;
  height: 46px;
  top: -0px;
  right: -57px;
  background-image: radial-gradient(circle at 100% 0, transparent 0%, transparent 60px, #ffffff 60px);
}

.your-custom-class {
  width: 300px;
  right: -400px;
  top: -2px;
  transition: all 0.3s ease-in; 
}

.your-custom-class.moved {
  right: -2px;
}

.your-custom-class1{
  min-width: 500px;
  max-width: 500px;
  right: -860px;
  top: -2px;
  transition: all 0.3s ease-in; 
}

.your-custom-class1.moved {
  right: -2px;
}

.active-tab{
  background-color: rgb(255, 255, 255) !important;
}

.headStyle-active{
  background-image: radial-gradient(circle at 100% 0, transparent 0%, transparent 60px, #ffffff 60px);
}

.table_Inner th{
  font-size: 15px;
  font-weight: 400;
}
.table_Inner tbody tr td {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  font-size: 15px;
  font-weight: 500;
}

.expandable-table .arco-table-col-has-sorter .arco-table-cell-with-sorter{
  display: flex;
  align-items: center;
  padding-left: 13px !important;
}

.table_Inner tr:nth-child(odd){
  background-color: inherit !important;
}

.Inner_Expand_Table .arco-table-body{
  height: calc(100vh - 380px) !important;
  max-height: inherit !important;
} 
.collection_Inner_checkbox .arco-checkbox-checked .arco-checkbox-mask {
  background-color: var(--mastercolor) !important;
}

.collection_Inner_checkbox .arco-checkbox-mask{
  border: 2px solid var(--mastercolor);
}

.checkBox_PopUp{
  min-width: 370px;
  border-radius: 12px 0px 0px 12px;
  top: 95px;
  transition: all .3s ease-in;
  right: -400px;
}


.checkBox_PopUp.moving {
  right: -2px;
}

.checkBox_PopUp::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 14px;
  background: var(--mastercolor2_d2);
  left: 0;
  bottom: 0;
  top: 0;
  border-radius: 12px 0px 0px 12px;
}
.reportsBtn:hover{
  background: var(--mastercolor) !important;
}
.reportsBtn{
  transition: all .3s ease-in;
}

.reportsBtn:hover .reportsBtnIcon {
  fill: rgb(255, 255, 255); /* Change the icon color to red on hover */
}

.expandable-table2 .arco-table-body{
  height: calc(100vh - 457px) !important;
  max-height: inherit !important;
} 

.customise_table .arco-table-th:nth-child(2) {
  width: 120px;
  min-width: 100px;
}

.customise_table .arco-table-td:nth-child(2) {
  width: 120px;
  min-width: 100px;
  padding-left: 36px;
}

.customise_table2 .arco-table-th:nth-child(1) {
  width: 120px;
  min-width: 100px;
}

.customise_table2 .arco-table-td:nth-child(1) {
  width: 120px;
  min-width: 100px;
  padding-left: 35px;
}

.customise_table3 .arco-table-th:last-child {
  width: 75px;
  min-width: 70px;
}

.customise_table3 .arco-table-td:last-child {
  width: 75px;
  min-width: 70px;
  padding-left: 35px;
}

.expand_inner_table .arco-table-body{
  height: inherit !important;
  max-height: inherit !important;
}

.customise_table .expand_inner_table .arco-table-td:nth-child(2){
  width: inherit !important;
  min-width: inherit !important;
}

.customise_table3 .expand_inner_table .arco-table-td:last-child{
  width: inherit !important;
  min-width: inherit !important;
}

.customise_table3 .expand_inner_table .arco-table-td:last-child {
  padding-left: 20px;
}

.customise_table .expand_inner_table .arco-table-td:nth-child(2){

  padding-left: 20px;
}

.menu-demo .css-1y7khq2{
 background: #a6ce39 !important;
 border-radius: 8px;
}

.menu-demo .css-6s48aw >.ps-menu-button:hover{
  border-radius: 8px;
}

.menu-demo .css-nwj8xd >.ps-menu-button:hover {
  background-color: #f3f3f330;
  border-radius: 8px;
}

.menu-demo .css-1y7khq2 >.ps-menu-button:hover {
  background-color: inherit;
  border-radius: 8px;
}

.menu-demo .css-ewdv3l .css-6s48aw{
   border-radius: 8px;
  margin-top: 0.5rem;
}
.menu-demo .css-nwj8xd {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.customRadio .arco-radio-button.arco-radio-checked{
  background-color: #a6ce39 !important;
  color: var(--white) !important;
}

.dashboardLayout{
  height: calc(100vh - 98px);
  overflow: auto;
}

.versionText {
  position: absolute;
  bottom: 1px;
  color: #ffffffb0;
  left: 5px;
}

.ErrorImg{
  height: 400px;
}

/* ----------- RESPONSIVE SECTIONS --------------- */
@media (max-width: 992px){  
 
}

@media (max-width: 768px){
  
}


@media (max-width: 550px){
  
}

@media (max-width: 400px){
}

@media (max-width: 300px){
 
}


